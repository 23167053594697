import $ from 'jquery';
window.$ = window.jQuery = $;
import 'bootstrap';
import 'jquery-simple-mobilemenu';
import 'jquery-slinky';
import Swiper from 'swiper';
import datepicker from 'bootstrap-datepicker';

$(document).ready(function () {
    $('.dmenu').hover(function () {
        $(this).find('.sm-menu').first().stop(true, true).slideDown(150);
    }, function () {
        $(this).find('.sm-menu').first().stop(true, true).slideUp(105)
    });
    
    var widthMenuSide = $('.box-side-menu').outerWidth()
    
    $('#openMegeMenu').click(function(){
        $('.box-side-menu').animate({left: 0}, 500)
        $(".bg-overlay").show()
        $('body').css({"overflow": "hidden"})
    })
    $('#closeSideMenu').click(function(){
        $('.box-side-menu').animate({left:'-' + widthMenuSide}, 500)
        $(".bg-overlay").hide()
        $('body').css({"overflow": "unset"})
    })
    
    $(document).click(function (e) {
        if (!$(e.target).closest('#openMegeMenu').length && $(e.target).parents(".box-side-menu").length === 0) {
            $('.box-side-menu').animate({left:'-' + widthMenuSide}, 500)
            $(".bg-overlay").hide()
            $('body').css({"overflow": "unset"})
        }
        if (!$(e.target).closest('#openSearch').length && $(e.target).parents("#boxSearch").length === 0) {
            $("#boxSearch").hide()
        }
    })

    $(".close-alert").click(function(){
        let hNavbar = $('.navbar-bottom').outerHeight() ? $('.navbar-bottom').outerHeight() : $('.navbar-bottom-2').outerHeight();
        $('#boxMegaMenu').css({"top": hNavbar})
    })
    
    $('#openSearch').click(function(){
        $('#boxSearch').show()
        // $('body').css({"overflow": "hidden"})
    })
    $('#closeSearch').click(function(){
        $('#boxSearch').hide()
        // $('body').css({"overflow": "auto"})
    })

    //$('.collapse').collapse()

    $('.parent-menu').click(function(){
        $('.child-menu').hide();
        $('#childMenu' + $(this).attr('target')).show();
        $('.parent-menu').removeClass('active');
        $(this).addClass('active');
    });

    $('.sideMenu').slinky()

    $(".mobile_menu").simpleMobileMenu({
        //Hamburger Id
        "hamburgerId" : "sm_menu_ham",
        //Menu Wrapper Class
        "wrapperClass" : "sm_menu_outer",
        //Submenu Class
        "submenuClass" : "submenu",
        // Callback - Menu loaded
        "menuStyle" : "accordion",
        "onMenuLoad" : function(menu) {
            //
        },
        //Callback - menu toggle(open/close)
        "onMenuToggle" : function(menu,open) {
            //
        }
    });

    $(window).scroll(function(e) {
        var heightNavbar = $('nav.navbar.navbar-top').outerHeight()

        var scrollTop = $(this).scrollTop();

        if(heightNavbar < scrollTop){
            $('nav.navbar.navbar-top').hide()
            // $('nav.navbar.navbar-middle').hide()

            $('nav.navbar-bottom').addClass('fixed-top')

            // $('nav.navbar-bottom').css('padding-top', '10px')
        } else {
            $('nav.navbar.navbar-top').show()
            // $('nav.navbar.navbar-middle').show()

            $('nav.navbar-bottom').removeClass('fixed-top')
            // $('nav.navbar-bottom').css('padding-top', '10px')
        }
    });

    // var widthBannerImage = $('.card.banner .card-img-top').innerWidth();
    // $('.card.banner .card-img-top').height(widthBannerImage - 30);

    // var widthBannerPromotionImage = $('.card.promotion .card-img-top').innerWidth();
    // $('.card.promotion .card-img-top').height(widthBannerPromotionImage);
});

var galleryThumbs = new Swiper('.gallery-thumbs', {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true
});
var galleryTop = new Swiper('.gallery-top', {
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    thumbs: {
        swiper: galleryThumbs
    }
});

// var $keyup1 = document.getElementById("inputSearchList");
// $keyup1.addEventListener('keyup', function(){
//     var filter, ul, li, a, i, h3, txtValue;
//     filter = $keyup1.value.toUpperCase();
//     ul = document.getElementById("filterSearchContent");
//     li = ul.getElementsByTagName("li");
//     var countL = 0;
//     for (i = 0; i < li.length; i++) {
//         a = li[i].getElementsByTagName("a")[0];
//         if(a){
//             txtValue = a.textContent || a.innerText;
//             if (txtValue.toUpperCase().indexOf(filter) > -1) {
//                 li[i].style.display = "";
//                 countL++;
//             } else {
//                 li[i].style.display = "none";

//             }
//         }
//         h3 = li[i].getElementsByTagName("h3")[0];
//         if(filter){
//             if(h3){
//                 h3.style.display = "none";
//                 document.getElementById("groupBrandList").style.display = "none";
//             }
//         } else {
//             if(h3){
//                 h3.style.display = "block";
//                 document.getElementById("groupBrandList").style.display = "block";
//             }
//         }
//     }
//     if(countL > 0){
//         document.getElementById("noBrands").style.display = "none";
//         document.getElementById("filterSearchListBox").style.display = "block";
//     } else {
//         document.getElementById("noBrands").style.display = "block";
//         document.getElementById("filterSearchListBox").style.display = "none";
//     }
// });

// $("#groupBrandList li a").click(function() {
//     var container = $('.filter-search-list-box'),id = $(this).attr('href'),
//         scrollTo = $(id);
        
//     container.animate({
//         scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
//     });

//     return false
// });


// var $keyup = document.getElementById("inputSearchList1");
// $keyup.addEventListener('keyup', function(){
//     var filter1, ul1, li1, a1, i1, h31, txtValue1;
//     filter1 = $keyup.value.toUpperCase();
//     ul1 = document.getElementById("filterSearchContent1");
//     li1 = ul1.getElementsByTagName("li");
//     var countL1 = 0;
//     for (i1 = 0; i1 < li1.length; i1++) {
//         a1 = li1[i1].getElementsByTagName("a")[0];
//         if(a1){
//             txtValue1 = a1.textContent || a1.innerText;
//             if (txtValue1.toUpperCase().indexOf(filter1) > -1) {
//                 li1[i1].style.display = "";
//                 countL1++;
//             } else {
//                 li1[i1].style.display = "none";

//             }
//         }
//         h31 = li1[i1].getElementsByTagName("h3")[0];
//         if(filter1){
//             if(h31){
//                 h31.style.display = "none";
//                 document.getElementById("groupBrandList1").style.display = "none";
//             }
//         } else {
//             if(h31){
//                 h31.style.display = "block";
//                 document.getElementById("groupBrandList1").style.display = "block";
//             }
//         }
//     }
//     if(countL1 > 0){
//         document.getElementById("noBrands1").style.display = "none";
//         document.getElementById("filterSearchListBox1").style.display = "block";
//     } else {
//         document.getElementById("noBrands1").style.display = "block";
//         document.getElementById("filterSearchListBox1").style.display = "none";
//     }
// });

// $("#groupBrandList1 li a").click(function() {
//     var container = $('#filterSearchListBox1'),id = $(this).attr('href'),
//         scrollTo = $(id);
//     container.animate({
//         scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
//     });

//     return false
// });


$("#openSideNavSearch").click(function(){
    $("#sidenavSearch").width("100%");
});
$("#closeSideNavSearch").click(function(){
    $("#sidenavSearch").width("0");
});

$("#openSideNavFilter").click(function(){
    $("#sidenavFilter").width("100%");
});
$("#closeSideNavFilter").click(function(){
    $("#sidenavFilter").width("0");
});

var swiper = new Swiper('.swiper-container-instagram', {
    slidesPerView: 6,
    spaceBetween: 15,
    freeMode: true,
    pagination: false,
    loop:true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    breakpoints: {
        1024: {
            slidesPerView: 6,
            spaceBetween: 40
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});

var swiperService = new Swiper('.swiper-container-service', {
    slidesPerView: 3,
    spaceBetween: 15,
    freeMode: true,
    pagination: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 15
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 15
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 15
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});

var swiperTestimonial = new Swiper('.testimonial', {
    speed: 600,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});

// //filter service
// $('#selectLocationService').on('change', function(){
//     window.location.href = 'https://lunadorii.com/services?location=' + $(this).find(":checked").val() + '&store=' + $('#iSelectStore').val() + '&date=' + $('#iSelectDate').val()
// })
// $('#selectStoreService').on('change', function(){
//     window.location.href = 'https://lunadorii.com/services?location=' + $('#iSelectLocation').val() + '&store=' + $(this).find(":checked").val() + '&date=' + $('#iSelectDate').val()
// })
// $('#selectDateService').datepicker().on('changeDate', function(){
//     window.location.href = 'https://lunadorii.com/services?location=' + $('#iSelectLocation').val() + '&store=' + $('#iSelectStore').val() + '&date=' + $('#selectDateService').val()
// });

// //filter event
// $('#selectLocationEvent').on('change', function(){
//     window.location.href = 'https://lunadorii.com/events?category=' + $('#iSelectCategory').val() + '&location=' + $(this).find(":checked").val() + '&store=' + $('#iSelectStore').val() + '&date=' + $('#iSelectDate').val()
// })
// $('#selectStoreEvent').on('change', function(){
//     window.location.href = 'https://lunadorii.com/events?category=' + $('#iSelectCategory').val() + '&location=' + $('#iSelectLocation').val() + '&store=' + $(this).find(":checked").val() + '&date=' + $('#iSelectDate').val()
// })
// $('#selectDateEvent').datepicker().on('changeDate', function(){
//     window.location.href = 'https://lunadorii.com/events?category=' + $('#iSelectCategory').val() + '&location=' + $('#iSelectLocation').val() + '&store=' + $('#iSelectStore').val() + '&date=' + $('#selectDateEvent').val()
// });

// document.addEventListener("contextmenu", function(e){
//     if (e.target.nodeName === "img") {
//         e.preventDefault();
//     }
// }, false);

$('.datepicker').datepicker();

//Function to animate slider captions 
function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
        var $this = $(this),
            $animationType = $this.data('animation');
        $this.addClass($animationType).one(animEndEv, function () {
            $this.removeClass($animationType);
        });
    });
}

//Variables on page load 
var $myCarousel = $('#carouselHome'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
    
//Initialize carousel 
$myCarousel.carousel();

//Animate captions in first slide on page load 
doAnimations($firstAnimatingElems);

//Pause carousel  
$myCarousel.carousel('pause');


//Other slides to be animated on carousel slide event 
$myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
});  


$("#navbarBrand").hover(function(){
    $('#iconMainan').attr("src", $('#iconMainan').attr('data-gif'))
}, function(){
    $('#iconMainan').attr("src", $('#iconMainan').attr('data-png'))
});